<template>
  <div id="app">
    <div id="app" v-if="!logged">
      <b-alert :show="isAlertShowing" :fade="true" dismissible :variant="alertColor">{{ $i18n.t(alertMessage) }}</b-alert>
    </div>
    <transition name="app-fade" mode="out-in">
      <auth v-if="!logged" @login="login" />
      <div v-if="logged">
        <header v-if="!isFullscreen">
          <logo-headNav @logout="logout">
            <template #logo>
				<div class="logoAndVersionContainer"  @click="()=>{isHoveringLogo = false}" @mouseover="()=>{isHoveringLogo = true}" @mouseleave="()=>{isHoveringLogo = false}">
					<div class="logoWrapper">
						<b-img v-if="!isHoveringLogo" class="tmhub" center :src="require('./assets/tmHub_white.png')" fluid />
						<b-img v-else center class="mtwLogo" :src="require('./assets/mtw_white.png')" fluid />
					</div>
					<strong>v1.0.46.14</strong>
				</div>
            </template>
          </logo-headNav>
          <menu-sidebar @hideSideBar="hideSideBar()" @showSideBar="showSideBar()" />
        </header>
        <transition name="loading">
          <div id="main" v-if="!loading">
            <main :style="mainStyle">
				<transition name="router-fade" mode="out-in">
					<router-view @toggleFullscreen="toggleFullscreen" />
				</transition>
            </main>
          </div>
        </transition>
		<b-alert :show="isAlertShowing" :fade="true" dismissible :variant="alertColor" class="position-fixed fixed-bottom m-0 rounded-0 api-error">
			{{$i18n.t(alertMessage).replace("{target}", $i18n.t(errorOrigin))}}
		</b-alert>
      </div>
    </transition>
  </div>
</template>
  
<script>
/* eslint-disable */
import Auth from './pages/Auth.vue'
import DashboardPanel from '../../UtilsTelemetry/components/dashboard/DashboardPanel/index.vue'
import api from "../../UtilsTelemetry/api"
import Presentation from './pages/Presentation.vue'
import PresentationHome from './pages/PresentationHome.vue'
import MobileHome from './pages/Mobile.vue'
import User from './pages/User.vue'
import ToastContent from '../../Utils/components/shared/misc/Toast.vue'
export default {
	components: {
		'Auth': Auth,
	},
	data() {
		return {
			isHoveringLogo: false,
		refresh: true,
		id: -1,
		isFullscreen: false,
		mainStyle: {
			'margin-left': '0',
		},
		mobilePrivilege: ['Dashboard', 'Map'],

		mtwPrivilege: ['CustomMap', 'Servers', 'Dashboard','User','Group','Display','ColorConfig','Map','Device',
			'History','Variable','Schedule','Measurer','Alarm','AlarmConfiguration',
			'Action','Recipient','User','SerialNumber', 'RecipientGroup',  'Presentation', 'Profile', 'Audit', 'Layer', 'HistoryEvents'],

		adminPrivilege: ['Dashboard','User','Group','Display','ColorConfig','Map','Device',
			'History','Variable','Schedule','Measurer','Alarm','AlarmConfiguration',
			'Action','Recipient','User', 'Presentation', 'RecipientGroup', 'Presentation', 'Profile', 'Layer','HistoryEvents'],

		operatorPrivilege: ['Dashboard','Group','Display','ColorConfig','Map','Device',
			'History','Measurer','AlarmConfiguration', 'Presentation', 'RecipientGroup', 'Profile', 'Layer', 'HistoryEvents'],

		isAlertShowing: false,
		alertMessage: "Sucesso",
		alertColor: "success",
		errorOrigin: '',
		loading: false,
		rotation: "90deg",

		}
	},

	methods: {
		showToast(message) {
			const router = this.$router
			const waitNextTick = async () => await this.$nextTick()
			const toast = message.isAlarm ?
				message.value == "Normal" ? this.$toast.success : this.$toast.error
				: message.value == "Online" ? this.$toast.success : this.$toast.warning

			const toastId = toast({
				component: ToastContent,
				props: { message: message, i18n: this.$i18n },
				listeners: {
					redirect: async (route) => {
						router.push('/dashboard').catch(() => { }); // se entrar no erro quer dizer que está no /dashboard
						await waitNextTick()
						router.push(route);
					},
					close: () => {
						this.$toast.dismiss(toastId)
					}
				},
			},
				{
					position: "bottom-right",
					timeout: 10000,
					closeOnClick: false,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					hideProgressBar: false,
					closeButton: false,
					showCloseButtonOnHover: true,
					icon: false,
					rtl: false
				});

		},

		showAlert() {
			this.isAlertShowing = true
			setTimeout(() => {
				this.isAlertShowing = false
			}, 10000)
		},

		/**Função que pega a chave da tradução da mensagem que a api recebe quando faz uma requisição */
		getResponseMessageTranslationKey(message) {
			if (message.length <= 0) return ''
			/*A mensagem vem como: "resposta da requisição"
			* deve ser transformado para "respostaDaRequisição"
			*/
			const words = message.split(' '); //separa as palavras
			// deixa a primeira letra de cada palavra maiuscula
			const capitalizedWords = words.map((word, index) => {
				if (index == 0) return word
				const firstLetter = word.charAt(0).toUpperCase();
				const restOfWord = word.slice(1);
				return firstLetter + restOfWord;
			});
			const camelCaseString = capitalizedWords.join('').replace(/\s/g, '');
			return camelCaseString
		},

		login() {
			this.refresh = !this.refresh;
			this.id = this.$session.get('logged_id');
			this.loadRoutes();
			this.reloadDashboardRoutes()
			this.$router.push('/').catch(() => { });
			clearInterval(this.reloadDashboardRoutes)
			clearInterval(this.reloadNotifications)
			this.dashboardUpdateInterval = setInterval(this.reloadDashboardRoutes, 15000)
			this.notificationsInterval = setInterval(this.reloadNotifications, 1000)
		},

		logout() {
			this.refresh = !this.refresh;
			this.$session.set('logged_id', 0);
			this.hideSideBar()
		},

		showSideBar() {
			var margin = window.innerWidth <= 991 ? "0" : "15rem"
			this.mainStyle = {
				transition: '0.5s',
				'margin-left': margin,
			}
		},

		hideSideBar() {
			this.mainStyle = {
				transition: '0.5s',
				'margin-left': '0',
			}
		},
		toggleFullscreen(state) {
			this.isFullscreen = state
			this.$root.$emit('bv::toggle::collapse', 'sidebar')
			this.hideSideBar()
		},

		addRoute(name) {
			var route = '';
			if (name == "Map") route = { path: `/Map/:layerId?`, name: `Map`, component: () => import(`./pages/Map.vue`), title: `Map`, menu: false }
			else if (name != "") route = { path: `/${name}`, name: `${name}`, component: () => import(`./pages/${name}.vue`), title: `${name}`, menu: false };
			else {
				var homepage
				if (this.isMobile) { homepage = MobileHome }
				else if (this.$session.get('privilege') == '0' || this.$session.get('privilege') == '1') { homepage = User }
				else { homepage = PresentationHome }
				route = {
					path: `/`,
					name: 'home',
					component: homepage,
					title: 'home',
					menu: false,
					props: true,
				}
			}
			this.$router.addRoute(route);
			this.$router.options.routes.push(route);
		},

		loadRoutes() {
			this.$router.options.routes.length = 0;

			if (this.isMobile) this.mobilePrivilege.forEach(el => this.addRoute(el))
			else if (this.$session.get('privilege') == '0') this.mtwPrivilege.forEach(el => this.addRoute(el));
			else if (this.$session.get('privilege') == '1') this.adminPrivilege.forEach(el => this.addRoute(el));
			else if (this.$session.get('privilege') == '2') this.operatorPrivilege.forEach(el => this.addRoute(el));
			this.addRoute('');
			var dashboardRoute = {
				path: `/dashboard/:id?`,
				name: 'Dashboard',
				component: DashboardPanel,
				title: 'dashboardPanel',
				menu: true,
				props: true,
			}
			this.$router.addRoute(dashboardRoute);
			this.$router.options.routes.push(dashboardRoute);

			if (this.$session.get('privilege') == '0') return
			var route = {
				path: `/presentation/:begin`,
				name: 'presentation',
				params: { begin: 'disable' },
				component: Presentation,
				title: 'presentation',
				menu: true,
				props: true,
			}

			this.$router.addRoute(route);
			this.$router.options.routes.push(route);
		},

		reloadDashboardRoutes() {
			var user = this.$session.get('user')
			this.dashboardService
				.listByUserId(this.id)
				.then(dashboardList => {
					user.dashboards = dashboardList
					this.$session.set("user", user)
					dashboardList.forEach(dashboard => {

						var dashboardObj = { id: dashboard.id, name: dashboard.name }
						this.variableService
							.listByDashboardId(dashboard.id, user.id)
							.then(variables => {
								variables.forEach(variable => {
									var topic = `${variable.device.serialNumber}/${variable.module}`
									// eslint-disable-next-line
									Status.AddDashboardToStatusMap(topic, dashboardObj)
								})
							})

					})
				})
		},

		async getTopicsInUse(){
			var topicsInUse = []
			await this.variableService
			.listByUserId(this.id)
			.then(el=>{
				var variables = el.filter(v => v.enable)
				var allTopics = variables.map(item => {
					return `${item.device.serialNumber}`
				})
				topicsInUse = Array.from(new Set(allTopics))
			})

			return topicsInUse
		},

		async getAllAlarmVariableIds(){
			var variableIds = []
			await this.alarmService
			.listByUserId(this.id)
			.then(alarms =>{
				variableIds = alarms.map(a => a.variable.id)
			})
			return Array.from(new Set(variableIds))
		},

		async reloadNotifications() {
			var topics = await this.getTopicsInUse()
			var variableIds = await this.getAllAlarmVariableIds()
			for(let sn of topics){
				await this.statusService
				.getValueBySn(sn)
				.then(list => {
					list.forEach(slot =>{
						let res = [{id: slot.id, name: slot.name, datetime: slot.datetime, unit: slot.unit, value: slot.value == "1" ? 'Online' : 'Offline'}]
						let topic = `${sn}/${slot.id}`
						// eslint-disable-next-line
						Status.UpdateStatus(topic, res)
						// eslint-disable-next-line
					})
				})
			}
			for (let variable of variableIds){
				await this.alarmDataService
				.getValueByVariableId(variable)
				.then(alarms => {
					alarms.forEach(alarm => {
						let name = `alarm/${alarm.id}`
						// eslint-disable-next-line
						Alarm.UpdateAlarm(name, alarm)
					})
				})
			}
			this.loadAlarmNotifications()
			this.loadStatusNotifications()
		},

		loadStatusNotifications(){
			// eslint-disable-next-line
			const statusMap = Status.GetStatusMap()
			statusMap.forEach(async (item) => {
				const topic = item.name
				const value = item.value[0]
				if (!value.show) return
				var affectedDashboards = []
				var affectedLayers = []
				for (let variableId of this.getVarCodesBySerialNumberAndModule(topic)) {
					await this.variableService
						.searchDashboardsAndLayersById(variableId, this.$session.get("logged_id"))
						.then(element => {
							affectedDashboards = affectedDashboards.concat(element.dashboards)
							affectedLayers = affectedLayers.concat(element.layers)
						})
				}
				// eslint-disable-next-line
				Status.SetStatusShow(topic)
				const message = {
					isAlarm: false,
					value: value.value,
					target: `${value.name} (${topic.split("/")[0]})`,
					time: value.datetime.split(" ")[1],
					dashboards: affectedDashboards,
					layers: affectedLayers
				}
				this.showToast(message)
			})
		
		},

		loadAlarmNotifications(){
			// eslint-disable-next-line
			const alarmMap = Alarm.GetAlarmMap()

			alarmMap.forEach(async item => {
				const { name, value } = item
				if (!value.show) return
				var listOfAlarmTypes = ["Normal", "Pre-Ativo", "Ativo"]
				var alarmType = listOfAlarmTypes[parseInt(value.value)]
				var affectedDashboards = []
				var affectedLayers=[]
				await this.alarmService
				.search(value.id)
				.then(async alarm => {
					const variableId = alarm.variable.id
					await this.variableService
						.searchDashboardsAndLayersById(variableId, this.$session.get("logged_id"))
						.then(element => {
							affectedDashboards = affectedDashboards.concat(element.dashboards)
							affectedLayers = affectedLayers.concat(element.layers)
						})

				})
				const message = {
					isAlarm: true,
					value: alarmType,
					target: value.name,
					time: value.datetime.split(" ")[1],
					dashboards: affectedDashboards,
					layers: affectedLayers
				}
				this.showToast(message)
				// eslint-disable-next-line
				Alarm.SetAlarmShow(name)
			})

		},

		getVarCodesBySerialNumberAndModule(topic) {
			const varCodes = [];
			// eslint-disable-next-line
			const dataArray = Variable.GetVariableMap()
			dataArray.forEach(item => {
				if (item.name.startsWith(topic + "/")) {
					const varCode = parseInt(item.name.split("/")[2]);
					varCodes.push(varCode);
				}
			});

			return varCodes;
		}

	},

	computed: {
		logged() {
			this.refresh;
			return this.$session.get('logged_id') > 0;
		},
	},

	async created() {
		this.$session.start();
		this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		this.$session.set('isMobile', this.isMobile)
		this.dashboardService = new this.$dashboardService();
		this.variableService = new this.$variableService();
		this.alarmService = new this.$alarmService();
		this.statusService = new this.$statusService();
		this.alarmDataService  = new this.$alarmDataService();

		this.id = this.$session.get('logged_id');
		if (this.id > 0) {
			this.loadRoutes();
			this.reloadDashboardRoutes();
			this.dashboardUpdateInterval = setInterval(this.reloadDashboardRoutes, 15000)
			this.notificationsInterval = setInterval(this.reloadNotifications, 1000)
		}

		api.interceptors.request.use(
			request => {
				if (request.method == 'post' || request.method == 'put' || request.method == 'delete') {
					if (!request.url.includes("/api/Data/") && request.url !== "/api/Message" && !request.url.includes("/api/Displays") && !request.url.includes("/api/ColorConfigs")) {
						this.loading = true;
					}
				}
				return request;
			}, error => {
				return Promise.reject(error);
			});

		api.interceptors.response.use(
			response => {
				if (response.data.message != undefined) {
					if (response.data.message.length > 0) {
						this.alertMessage = this.getResponseMessageTranslationKey(response.data.message);
						this.alertColor = "success";
						if (this.alertMessage.length > 0) this.showAlert();
					}
				}
				setTimeout(() => {
					this.loading = false
				}, 1000);
				return response
			},
			error => {
				console.error(error)
				
				const message = error.response.data.message == undefined ? error.response.data : error.response.data.message
				this.alertColor = "danger"
				this.alertMessage = this.getResponseMessageTranslationKey(message)
				this.errorOrigin = error.config.url.split('/')[2].slice(0, -1)
				console.warn(this.alertMessage, this.errorOrigin)
				if (this.alertMessage.length > 0) this.showAlert()
				setTimeout(() => {
					this.loading = false
				}, 1000);
				return Promise.reject(error)
			}
		)

	},
	beforeDestroy() {
		clearInterval(this.dashboardUpdateInterval)
		clearInterval(this.notificationsInterval)
	}
}

</script>
  
<style lang="scss">
@import './assets/css/cores.css';
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


.app-fade-enter-active,
.app-fade-leave-active {
	transition: all 1s;
}

.app-fade-enter,
.app-fade-leave-to {
	opacity: 0;
}


.loading-enter-active,
.loading-leave-active {
	transition: all .5s;
}

.loading-enter,
.loading-leave-to {
	opacity: 0;
}


.router-fade-enter-active,
.router-fade-leave-active {
	transition: all .5s;
}

.router-fade-enter,
.router-fade-leave-to {
	opacity: 0;
}

nav.navbar.p-2.navbar-dark.bg-dark.navbar-expand {
	background-color: rgba(29, 40, 53, 0.95) !important;
	box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}


#app {
	font-family: "Chakra Petch", 'Avenir', Helvetica, Arial, sans-serif;
	color: white;
	/* text-align: center; */
	letter-spacing: 0.05rem;
	overflow-x: hidden;
	opacity: 0.85;
}

.logoAndVersionContainer{
	display: flex;
	align-items: flex-end;
	gap: 0.25rem;
	.logoWrapper{
		width: 150px;
	}
	strong{
		color: white;
		margin-bottom: 0rem;
		font-size: 0.66rem;
		opacity: 0.55;
		user-select: none;
	}
}

.mtwLogo{
	width: 120px;
	user-select: none;
	pointer-events: none;
}
a.router-link-active {
	color: #e08f0e !important;
	opacity: 1 !important;
}

body {
	height: 100%;
	background-color: #273136;
	background-image: linear-gradient(180deg, rgba(50, 70, 80, .9) 0, #0d101b 100%);
	font-family: "Chakra Petch, sans-serif";
}

body::before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	z-index: -1;
	/* background-image: linear-gradient(180deg,rgba(50,70,80,.9) 0,#0d101b 100%); */
	background-image: url("/src/assets/pattern.png");
	background-repeat: repeat;
	background-size: 80px 80px;
}

body::after {
	content: "";
	position: fixed;
	top: 0;
	height: 100%;
	left: 0;
	right: 0;
	z-index: -5;
	background-color: #273136;
	background-image: linear-gradient(180deg, rgba(50, 70, 80, .9) 0, #0d101b 100%);
	background-image: linear-gradient(180deg, rgba(50, 70, 80, .9) 0, #0d101b 100%), url("/src/assets/bg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: initial;
	height: 100%;
	transition: background .2s linear;
	background-size: cover;
}

.vue-grid-item.vue-resizable.cssTransforms {
	touch-action: none;
}

body {
	background-color: transparent;
}

@mixin borderInCorners {
	background:
		linear-gradient(to right, white 4px, transparent 4px) 0 0,
		linear-gradient(to right, white 4px, transparent 4px) 0 100%,
		linear-gradient(to left, white 4px, transparent 4px) 100% 0,
		linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
		linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
		linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
		linear-gradient(to top, white 4px, transparent 4px) 0 100%,
		linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
}

@mixin containerHeadTable {
	/* background-color: red; */
	width: 100vw;
	height: 100%;
	margin: 0 5rem;
	padding: 2rem 1rem;

	hr {
		width: 100%;
		margin: 0;
		padding: 0;
		background: #acacac;
		height: 2px;
		margin-bottom: 2rem;
	}

	tbody {
		color: white;
	}
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #e08f0e;
	border-radius: 10px;
}

::-webkit-scrollbar-track-piece {
	background: #1a283a;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #e08f0e;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #a06300;
}

.Vue-Toastification__toast.Vue-Toastification__toast--success.bottom-right {
	background: #0f400f !important;
	border: 1px solid #158b15 !important;

	.Vue-Toastification__progress-bar {
		background: #158b15 !important;
	}
}

.Vue-Toastification__toast.Vue-Toastification__toast--warning.bottom-right {
	background: #1e1d1c !important;
	border: 1px solid #df3e20 !important;

	.Vue-Toastification__progress-bar {
		background: #df3e20 !important;
	}
}

.Vue-Toastification__toast.Vue-Toastification__toast--error.bottom-right {
	border: 2px solid #df3131 !important;
	background: #410808 !important;

	.Vue-Toastification__progress-bar {
		background: #df3131 !important;
	}
}

.panel-link {
	text-decoration: underline;
	font-weight: 900;
	cursor: pointer;
	color: white;
}

.alert-danger.api-error{
    border: 2px solid #870e0e;
    background: #f76358;
    font-weight: bold;
    color: white;
}

</style>
  