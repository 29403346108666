import draggable from 'vuedraggable'
import GroupComponent from '../../group/GroupComponent/index.vue'
import DashboardGridLayout from "../DashboardGrid/index.vue"

export default {
	components: {
		draggable,
		DashboardGridLayout,
		GroupComponent
	},

	props: {
		id: {
			type: String,
			default: 0,
		},
		fullscreen: {
			type: Boolean,
			default: false,
			required: false,
		},
		zoom: {
			type: Number,
			default: 100,
			required: false,
		}
	},

	data() {
		return {
			time: '',
			zoomLevel: 100,
			dashboard: new this.$dashboard(),
			groups: [],
			varGroupKey: undefined,
			variables: { message: [] },
			isFullscreen: false,
			groupsKey: 0,
			isShowingAllIcons: true,
			measurer: 0,
			isLoading: true,
			refreshGrid: false,

		}
	},

	computed: {
		allowEditing() {
			return this.$session.get("user").privilege == 2 || this.$session.get("isMobile")
		},
		zoomInDisabled() {
			if (this.zoomLevel == 150) {
				return {
					"--cursor": "not-allowed",
					"--color": "#ffffff80"
				}
			}
			else {
				return {
					"--cursor": "pointer",
					"--color": "white"
				}
			}
		},
		zoomOutDisabled() {
			if (this.zoomLevel == 25) {
				return {
					"--cursor": "not-allowed",
					"--color": "#ffffff80"
				}
			}
			else {
				return {
					"--cursor": "pointer",
					"--color": "white"
				}
			}
		},
		alarmColors() {
			const { attentionColor, informColor, criticalColor } = this.dashboard
			return { attention: attentionColor, inform: informColor, critical: criticalColor }
		},
	},

	methods: {
		getTime(){
			this.time = new Date().toString().split(" ")[4]
		},
		gridChange(measurerList, id) {
			this.$refs[`Group-${id}`][0].gridChange(measurerList)
		},
		editMeasurer(measurer) {
			this.$refs.forms.openEdit({
				"component": 'dashboard-measurer-info',
				"element": measurer
			})
		},
		addElement(requisition) {
			const { value } = requisition
			if (value != undefined) {
				this.dashboard.groups.push(value);
			}

			this.service
				.update(this.dashboard, this.$session.get("logged_id"))
				.then((el) => {
					this.reloadItems();
				});

		},

		async updatePosition(){
			this.refreshGrid = true;
			await this.$nextTick()
			this.refreshGrid = false
		},
		updateElement(requisition){
			this.service
				.update(this.dashboard, this.$session.get("logged_id"))
				.then((el) => {
					this.reloadItems();
				});
		},


		showModal(measurer) { // Mostra Modal
			this.measurer = measurer;
			this.$bvModal.show('modalConfirmDelete');
		},
		deleteCard() { // Confirma deleção, chama método confirDelete de DashboardGridLayout

			if (this.measurer.id != undefined && this.measurer.id > 0) {
				this.measurerService
					.erase(this.measurer, this.$session.get('logged_id'))
					.then(() => {
						this.reloadItems();
					},
						(err) => console.log(err))
			}

			this.$bvModal.hide('modalConfirmDelete')
		},
		editCard() {
			this.$bvModal.hide('modalConfirmDelete')
			this.$refs.grid[this.varGroupKey].editMeasurer();
		},
		goFullscreen() {
			const element = document.documentElement
			if (element.requestFullscreen) element.requestFullscreen();
			else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen(); /* Safari */
			else element.msRequestFullscreen(); /* IE11 */
		},
		exitFullScreen(){
			if (document.fullscreenElement) 
				document.exitFullscreen()
			else 
				document.documentElement.requestFullscreen();
		},
		zoomOut() {
			if (this.zoomLevel > 25) this.zoomLevel = this.zoomLevel - 25
			this.$emit("zoomChange", this.zoomLevel);

		},
		zoomIn() {
			if (this.zoomLevel < 150) this.zoomLevel = this.zoomLevel + 25
			this.$emit("zoomChange", this.zoomLevel);
		},
		resetZoom() {
			this.zoomLevel = 100
		},

		async reloadItems() {
			await this.service
				.searchByUserId(this.id, this.$session.get('logged_id'))
				.then(async (dashboard) => {
					this.dashboard = dashboard;
					this.$session.set("currentDashboard", dashboard)
					this.groups = dashboard.groups;
					this.groupsKey += 1;
				});
		},

		hideCursor() {
			clearTimeout(this.checkMousePositionTimeout)
			document.querySelector("#app").style.cursor = 'auto'
			this.isShowingAllIcons = true
			this.checkMousePositionTimeout = setTimeout(() => {
				this.isShowingAllIcons = false
				document.querySelector("#app").style.cursor = 'none'
			}, 5000)
		},
		toggleOpenFullscreenButtons(){
			var buttons = document.querySelector(".dashboardFullScreenButtons")
			var indicator = document.querySelector(".fullscreenIndicator")
			buttons.classList.toggle('open')
			indicator.classList.toggle('open')
		}
	},


	async mounted() {
		this.getTime()
		this.timeUpdateInterval = setInterval(()=>{
			this.getTime()
		}, 1000)
		this.isLoading = true
		// Verifica se existe um dashboard com esse id
		const avaliableDashboards = this.$session.get('user').dashboards.map(dashboard => dashboard.id)
		if (!avaliableDashboards.includes(parseInt(this.id))) {
			this.$router.push('/dashboard').catch(err => { });
			return
		}

		this.service = new this.$dashboardService();
		this.deviceService = new this.$deviceService();
		this.measurerService = new this.$measurerService();
		this.messageController = new this.$messageController();
		this.groupService = new this.$groupService();
		this.messageService = new this.$messageService();

		await this.reloadItems();

		this.isLoading = false

		this.isShowingAllIcons = true
		document.addEventListener("fullscreenchange", (e) => {
			this.isFullscreen = !this.isFullscreen
			this.$emit("toggleFullscreen", this.isFullscreen)
			document.getElementById("dashboardArea").style.zoom = 1
			this.zoomLevel = 100
		})


		if (this.fullscreen) {
			this.isFullscreen = !this.isFullscreen
			this.zoomLevel = this.zoom;
		}
	},
	watch: {
		zoomLevel() {
			const level = this.zoomLevel / 100
			const controller = document.querySelector('.fullscreenIndicatorContainer')
			const clock = document.querySelector('.timeContainer')
			document.body.style.zoom = level
			if(controller){
				controller.style.zoom = 1/level
			}
			if(clock){
				clock.style.zoom = 1/level
			}

		}
	},

	beforeDestroy() {
		document.body.style.zoom = 1
		clearInterval(this.timeUpdateInterval)
		// window.removeEventListener("mousemove", this.hideCursor)
		// document.querySelector("#app").style.cursor = 'default'
	}
}