import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from "vue-session"
import { routes } from './routes';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { i18n } from  '../../Utils/lang/lang'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vSelect from 'vue-select'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'animate.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


import SelectInput                        from "../../Utils/components/shared/Input/SelectInput.vue"
import TextInput                          from "../../Utils/components/shared/Input/TextInput.vue"
import Button                             from "../../Utils/components/shared/button/MtwButton.vue"
import Label                              from "../../Utils/components/shared/label/Label.vue"
import Modal                              from "../../Utils/components/shared/modal/Modal.vue"
import ModalUser                          from "../../Utils/components/shared/modal/ModalUser.vue"
import Sidebar                            from "../../Utils/components/shared/sidebar/TransparentSidebar.vue"
import HeadNavWithLogo                    from "../../Utils/components/shared/navbar/HeadNavWithLogo.vue"
import RelationTable                      from "../../Utils/components/shared/table/RelationTableWithBorders.vue"
import TableBordered                      from "../../Utils/components/shared/table/TableBordered.vue"
import PageHeader                         from "../../Utils/components/shared/misc/PageHeader.vue"
import ObjectSelect                       from "../../Utils/components/shared/select/ObjectSelectWithSearch.vue"
import Checkbox                           from "../../Utils/components/shared/checkbox/Checkbox.vue"
import Slider                             from "../../Utils/components/shared/Input/SliderInput.vue"
import TabPage                            from "../../Utils/components/shared/tabpage/TabPage.vue"
import card                               from "../../Utils/components/shared/card/CardInfo.vue"
import CnpjCpfInput                       from "../../Utils/components/shared/Input/SelectCpfOrCnpjInput.vue"
import TableSelectAlarmPriorityColor      from '../../Utils/components/shared/table/TableSelectAlarmPriorityColor.vue'
import CsvInput                           from "../../Utils/components/shared/Input/CsvInput.vue"

import BarChart                           from "../../Utils/components/shared/d3/BarChart.vue"
import StackedBarChart                    from "../../Utils/components/shared/d3/StackedBarChart.vue"
import UtilsTelemetry                     from "../../UtilsTelemetry/utils-telemetry"




Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueSession);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 3,
  newestOnTop: true
});

Vue.component('vue-select', vSelect)
Vue.component('select-input', SelectInput)
Vue.component('text-input', TextInput)
Vue.component('action-button', Button)
Vue.component('text-label', Label)
Vue.component('info-modal', Modal)
Vue.component('test-modal', ModalUser)
Vue.component('menu-sidebar', Sidebar)
Vue.component('logo-headNav', HeadNavWithLogo)
Vue.component('relation-table', RelationTable)
Vue.component('info-table', TableBordered)
Vue.component('page-header', PageHeader)
Vue.component('object-select', ObjectSelect)
Vue.component('checkbox', Checkbox)
Vue.component("date-picker", DatePicker)
Vue.component("tab-page", TabPage)
Vue.component("BarChart", BarChart)
Vue.component("StackedBarChart", StackedBarChart)
Vue.component('Slider', Slider)
Vue.component('card', card);
Vue.component('cpf-cnpj-input', CnpjCpfInput);
Vue.component('select-color-table', TableSelectAlarmPriorityColor);
Vue.component("csv-input", CsvInput)



Vue.use(VueSidebarMenu)

Vue.directive('th-resizeCard', {
  bind: function(el, binding){
    setInterval(()=>{
      if(!el.dataset.lastWidth || !el.dataset.lastHeight){
        el.dataset.lastWidth = el.offsetWidth
        el.dataset.lastHeight = el.offsetHeight
      }else{
        if(el.dataset.lastWidth != el.offsetWidth || el.dataset.lastHeight != el.offsetHeight){
          el.dataset.lastWidth = el.offsetWidth
          el.dataset.lastHeight = el.offsetHeight
          binding.value(el.offsetWidth, el.offsetHeight)
        }
      }
    }, 100)
  }
})

const router = new VueRouter({ 
  routes, 
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('language') || 'en'
  return next()
})


var utilsTelemetry = new UtilsTelemetry(Vue);
utilsTelemetry.addAllPrototye(Vue).then(()=>{
  utilsTelemetry.addAllComponents(Vue)
  new Vue({
    el: '#app',
    router,
    i18n,
    render: h => h(App),
  })
  //Diretiva de atualizar as rotas de dashboard
  Vue.directive('th-updateRoutes-on', {
    bind(el, binding) {
      if(binding.arg == 'buttonAction'){
        el.addEventListener('click', ()=>{
          binding.value()
          setTimeout(()=>{
            var user = Vue.prototype.$session.get('user') 
      
            // lista paineis do usuário
            const dashboardService = new Vue.prototype.$dashboardService();
            dashboardService
            .listByUserId(Vue.prototype.$session.get("logged_id"))
            .then(dashboardList => {
              // router.options.routes
              user.dashboards = dashboardList
              Vue.prototype.$session.set('user', user) //atualiza usuario
              //Itera sob os paineis
              dashboardList.forEach(dashboard => {
                //adiciona rotas
                var route = { 
                  path: `/dashboard/:id`, 
                  name: dashboard.name,  
                  params: { id: dashboard.id},
                  component: import('../../UtilsTelemetry/components/dashboard/DashboardPanel/index.vue'), 
                  title: 'dashboardPanel', 
                  menu: true, 
                  props: true,
                }
                const element = router.options.routes.find(route => route.params?.id == dashboard.id )
                if(element == undefined){
                  router.addRoute(route);
                  router.options.routes.push(route);
                }
                else{
                  const index = router.options.routes.findIndex(route => route.params?.id == dashboard.id)
                  router.options.routes[index] = route
                }
              })

            })

          },1000)
        });
      }
    },
  })


  Vue.directive('th-disableLowPrivilegeBtn', {
    bind(el){
      const privilege = Vue.prototype.$session.get("user").privilege
      if(privilege == 2){
        el.setAttribute('disabled', '')
      }
    }
  })
});
