<template>
    <div>
        <div v-if="dashboards.length == 0" class="noDashboards">
            <b-icon-x-circle-fill/>
            <h1>Sem Paineis!</h1>
        </div>
        <Transition name="slide-fade">
            <div v-if="show && id > 0">
                <div class="w-100 details-container">
                    <component :is="'dashboard-panel'" :id="id.toString()" :fullscreen="true" :zoom="zoom" @zoomChange="zoomChange"/>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>

export default {
    props: {
        time: {
            type: Number,
            required: false,
            default: 10
        },
    },

    data() {
        return {
            presentationTime: 10,
            showTime: 2,
            intervalTime: 10*1000,
            show: true,
            run: false,
            first: true,
            dashboards: [],
            id: 0,
            iterator: 0,
            zoom: 100,
        }
    },

    methods: {

        zoomChange(zoom) {
            this.zoom = zoom;
        },

        setPresentation() {
            this.presentation  = setTimeout(() => {
                this.show = false;
                this.id = this.dashboards[this.iterator].id;
                this.iterator += 1;
                if(this.iterator == this.dashboards.length) {
                    this.iterator = 0;  
                } 
                console.log("load");


                this.presentation = setTimeout(() => {
                    console.log("show");
                    this.show = true;
                    this.setPresentation();
                    
                }, this.showTime*1000)
            }, this.presentationTime*1000);
        },

        startPresentation() {
            this.run = true;
            this.show = false;
            this.id = this.dashboards[this.iterator].id;
            this.iterator += 1;

            if(this.iterator == this.dashboards.length) {
                this.iterator = 0;  
            }

            this.presentation = setTimeout(() => {
                console.log("show");
                this.show = true;        
                this.setPresentation();
            }, this.showTime*1000);
        }
    },

    created() {
        console.warn(this.begin)
        console.log('CREATED');
        this.service = new this.$dashboardService();

        this.service
        .listByUserId(this.$session.get('logged_id'))
        .then(dashboards => {
            console.log(dashboards)
            this.dashboards = dashboards;
            this.startPresentation();
        })
    },

    beforeDestroy(){
        clearTimeout(this.presentation);
    }
    
}

</script>

<style lang="scss" scoped>
    .noDashboards{
        svg{
            font-size: 4rem;
        }
        height: 90vh;
        display: flex;
        flex-direction: column;
        justify-content: center;    
        align-items: center;
    }

    .slide-fade-enter-active {
    transition: all 0.8s ease-out;
    }

    .slide-fade-leave-active {
    transition: all 0.8s ease-out;
    }

    .slide-fade-enter{
        transform: translateX(100%);
        opacity: 0;
    }
    .slide-fade-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }

    .time {
        margin-left: 5%;
    }

    .relation {
        margin-left: 5%;
        width: 90%;
    }


</style>