import axios from "axios";

export class ApiController{
        static BASE_URL = "http://172.104.199.107:8085"
        // static BASE_URL = "https://localhost:7078"


        static API = axios.create({
                baseURL: ApiController.BASE_URL
        })

        static ChangeApiURL(newUrl){
                ApiController.BASE_URL = newUrl
                ApiController.API.defaults.baseURL = newUrl
        }
}

export default ApiController.API