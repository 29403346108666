import exportFromJSON from 'export-from-json'
export default class UtilsTelemetry{
    constructor(Vue){
        this.componenst = [
            'Action',
            'Alarm',
            "Audit",
            'AlarmConfiguration',
            "AlarmHistory",
            'ColorConfig',
            'Dashboard',
            'Device',
            'Display',
            'Group',
            'History',
            'Layer',
            'Map',
            'Measurer',
            'Recipient',
            'Schedule',
            'User',
            'Variable',
            'SerialNumber',
            "RecipientGroup"
        ]
        Vue.component('DefaultIndex', () => import('./components/shared/DefaultIndex'));
        Vue.component('ToggleComponentButton', ()=> import('./components/shared/ToggleComponentButton'))
        Vue.component('ToggleRelationTableButton', () => import('./components/shared/ToggleRelationTableButton'));
        Vue.component('map-modal', () => import('./components/shared/MapModal'));
        Vue.component('expandable-button', () => import('./components/shared/ExpandableButton'));
    }


    camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    async importElement(name, sufix = '') {
        var adjustedName = name.replace(/([A-Z])/g, '-$1').trim().toLowerCase() + sufix;
        return await import(`./domain/${name}/${adjustedName}`)
        .then((module) => {
            return module.default;
        })
        .catch((error) => {
            console.log("Error!-" + name)
            console.log(`./domain/${name}/${adjustedName}`)
        });
    }


    async addAllPrototye(Vue) {
        [Vue.prototype.$action, Vue.prototype.$actionHeader, Vue.prototype.$actionService] = await this.addPrototype('action');
        [Vue.prototype.$alarm, Vue.prototype.$alarmHeader, Vue.prototype.$alarmService] = await this.addPrototype('alarm');
        [Vue.prototype.$alarmConfiguration, Vue.prototype.$alarmConfigurationHeader, Vue.prototype.$alarmConfigurationService] = await this.addPrototype('alarmConfiguration');
        [Vue.prototype.$audit, Vue.prototype.$auditHeader, Vue.prototype.$auditService] = await this.addPrototype('audit');
        [Vue.prototype.$colorConfig, Vue.prototype.$colorConfigHeader, Vue.prototype.$colorConfigService] = await this.addPrototype('colorConfig');
        [Vue.prototype.$dashboard, Vue.prototype.$dashboardHeader, Vue.prototype.$dashboardService] = await this.addPrototype('dashboard');
        [Vue.prototype.$device, Vue.prototype.$deviceHeader, Vue.prototype.$deviceService] = await this.addPrototype('device');
        [Vue.prototype.$display, Vue.prototype.$displayHeader, Vue.prototype.$displayService] = await this.addPrototype('display');
        [Vue.prototype.$group, Vue.prototype.$groupHeader, Vue.prototype.$groupService] = await this.addPrototype('group');
        [Vue.prototype.$history, Vue.prototype.$historyHeader, Vue.prototype.$historyService] = await this.addPrototype('history');
        [Vue.prototype.$layer, Vue.prototype.$layerHeader, Vue.prototype.$layerService] = await this.addPrototype('layer');
        [Vue.prototype.$measurer, Vue.prototype.$measurerHeader, Vue.prototype.$measurerService] = await this.addPrototype('measurer');
        [Vue.prototype.$recipient, Vue.prototype.$recipientHeader, Vue.prototype.$recipientService] = await this.addPrototype('recipient');
        [Vue.prototype.$recipientGroup, Vue.prototype.$recipientGroupHeader, Vue.prototype.$recipientGroupService] = await this.addPrototype('recipientGroup');
        [Vue.prototype.$schedule, Vue.prototype.$scheduleHeader, Vue.prototype.$scheduleService] = await this.addPrototype('schedule');
        [Vue.prototype.$user, Vue.prototype.$userHeader, Vue.prototype.$userService] = await this.addPrototype('user');
        [Vue.prototype.$variable, Vue.prototype.$variableHeader, Vue.prototype.$variableService] = await this.addPrototype('variable');
        [Vue.prototype.$message, Vue.prototype.$messageHeader, Vue.prototype.$messageService] = await this.addPrototype('message');
        [Vue.prototype.$variableCodes, Vue.prototype.$variableCodesHeader, Vue.prototype.$variableCodesService] = await this.addPrototype('variableCodes');
        [Vue.prototype.$serialNumber, Vue.prototype.$serialNumberHeader, Vue.prototype.$serialNumberService] = await this.addPrototype('serialNumber');
        [Vue.prototype.$varData, Vue.prototype.$dataHeader, Vue.prototype.$dataService] = await this.addPrototype("data");
        [Vue.prototype.$status, Vue.prototype.$statusHeader, Vue.prototype.$statusService] = await this.addPrototype("status");
        [Vue.prototype.$alarmData, Vue.prototype.$alarmDataHeader, Vue.prototype.$alarmDataService] = await this.addPrototype("alarmData")
        
        Vue.prototype.$apiController = await import('./api')
        .then(module =>{
            return module.ApiController
        })

        Vue.prototype.$alarmOperatorService = await import('./domain/alarmOperator/alarm-operator-service')
        .then((module)=>{
            return module.default;
        })

        Vue.prototype.$alarmHistoryService = await import('./domain/alarmHistory/alarm-history-service')
        .then((module)=>{
            return module.default;
        })

        Vue.prototype.$alarmPrioritiesService = await import('./domain/alarmPriorities/alarm-priorities-service')
        .then((module)=>{
            return module.default;
        })

        Vue.prototype.$actionOperatorService = await import('./domain/actionOperator/action-operator-service')
        .then((module)=>{
            return module.default;
        })
        Vue.prototype.$messageController = await import(`./domain/message/message-controller`)
        .then((module => {
            return module.default;
        }))

        Vue.prototype.$exportToCSV = (name, fields, element, references) => {
            var exportDataObj = (obj, fields) => {
                let filteredObject = {};
                fields.forEach(field => {
                    if (obj.hasOwnProperty(field)) {
                        filteredObject[field] = obj[field];
                    }
                });
                return filteredObject;
            }

            var data = exportDataObj(element, fields)
            if(references){
                data = {...data, ...references}
            }

            const fileName = `${name}_${new Date().toISOString()}`
            const exportType =  exportFromJSON.types.csv
            exportFromJSON({ data: [data], fileName, exportType })
        }

    }

    addAllComponents(Vue) {
        this.componenst.forEach(element => {
            this.addComponent(Vue, element);
        });        
        //this.addCustomComponent(Vue, 'device','state')
        Vue.component('dashboard-measurer-info', async ()=> await import('./components/dashboard/DashboardMeasurerInfo'))
        Vue.component('dashboard-panel', async ()=> await import('./components/dashboard/DashboardPanel'))

        Vue.component(`UserRelation`, () => import(`./components/user/UserRelation`));  
        Vue.component(`UserSons`, () => import(`./components/user/UserSons`));  

        Vue.component(`LoginForm`, () => import(`./components/auth/Login`));
        Vue.component(`sign-up-form`, () => import(`./components/auth/SignUp`));

        Vue.component('map-component', ()=> import('./components/map/MapComponent'))
    }

    async addComponent(Vue, name) {
        Vue.component(`${name}Index`, () => import(`./components/${this.camelize(name)}/${name}Index`));
        Vue.component(`${name}Info`, () => import(`./components/${this.camelize(name)}/${name}Info`));
    }

    async addCustomComponent(Vue, name, component) {
        const component_address = `./components/${name}/${component}.vue`
        console.log(component_address)
        Vue.component(`${name}-${component}`, () => import(component_address));
    }

    async addPrototype(name, element, elementHeader, elementService) {
        element = await this.importElement(name);
        elementHeader = await this.importElement(name, '-header');
        elementService = await this.importElement(name, '-service');
        return [element, elementHeader, elementService];
    }

}