<template>
    <login-form  v-if="isInLoginPage" @toggleForm="toggleForm" @login="login()" :id="0"/>
    <sign-up-form v-else @backToLogin="backToLogin" @toggleForm="toggleForm"/>
</template>

<script>
export default {
    data(){
        return {
            isInLoginPage: true
        }
    },
    methods:{
        login(){
            this.$emit("login")
        },
        toggleForm(){
            this.isInLoginPage = !this.isInLoginPage
        },
        backToLogin(){
            this.isInLoginPage = true
        }
    },
}
</script>

<style>

</style>