import {
    GridLayout,
    GridItem
} from "vue-grid-layout"

export default {
    components: {
        GridLayout,
        GridItem,
    },
    props: {
        id: {
            type: Number,
            default: 0,
        },
        dashboardId: {
            type: String,
            default: 0
        },
        isShowingAllIcons: {
            type: Boolean,
            required: true
        },
        alarmColors: {
            required: false,
            type: Object
        },
        hideCardFooter:{
            required:false,
            default:false,
            type:Boolean
        },
        hideFooter:{
            type:Boolean,
            required:false,
            default:false
        }
    },
    data() {
        return {
            group: {},
            groupPositions: [],
            groupMeasurers: [],
            toBeDeleted: {},
            dataLoaded: false,
            showGrid: true,
            minCardWidth: 260,
            gridKey: 0,
            numOfCol: 6,
            isLoading: true,
        }
    },
    computed: {
        isMobile() {
            return this.$session.get("isMobile")
        }
    },
    methods: {
        update(element = this.group) {
            this.service.update(element).then((el) => { console.log("UPDATE") }, (err) => console.error(err))
        },
        deleteCard(measurer) { //Armazena as informações do medidor a ser deletado e emite um evento para DashboardPanel para mostrar modal 
            this.$emit("showModal", { id: measurer.id })
        },
        async changeGroup(group, index) {
            var groupMove = {}
            await this.service.search(group.id).then(response => {
                response.measurers.push(this.groupMeasurers[index])
                groupMove = response
            })
            this.group.measurers = this.group.measurers.filter(measurer => measurer.id != this.groupMeasurers[index].id)
            this.update(groupMove)
            this.update()
        },
        editMeasurer(measurer = this.toBeDeleted) {
            this.$emit('editMeasurer', measurer)
        },
        /** Função que adapta as colunas do grid layout do grupo */
        windowResize() {
            var w = Math.floor(window.innerWidth / this.minCardWidth) //Calcula a quantidade de colunas que o grid deverá ter
            this.numOfCol = w > 6 ? 6 : w < 1 ? 1 : w // O grid deve ter no minimo uma coluna e no máximo 6 colunas 
            this.reajustPositions() // reajusta as posições dos medidores
        },
        /** Fução que reajusta as posições dos medidores no grid */
        reajustPositions() {
            var colNum = 0
            var lineNum = 0
            this.groupPositions.forEach(position => {
                position.x = colNum
                position.y = lineNum
                colNum += 1
                if (colNum % this.numOfCol == 0) {
                    colNum = 0
                    lineNum += 1
                }
            })
            this.refreshGrid()
        },
        async refreshGrid() {
            this.showGrid = false
            await this.$nextTick()
            this.showGrid = true
        },
    },

    async created() {
        this.service = new this.$groupService();
        this.measurerService = new this.$measurerService();
        this.isLoading = true
        await this.service
        .searchByUserId(this.id, this.$session.get('logged_id'))
        .then((group) => {
            this.group = group;
            this.groupMeasurers = [];
            group.measurers.forEach((element)=>{

                element.display.cardName = element.name
                element.display.constant = 0
                element.display.multiplier = 1
                element.display.unit = element.variable.unit
                element.display.recovery = element.recovery

                var el = { 
                    x: element.positionX,
                    y: element.positionY,
                    w: element.width,   
                    h: element.height,
                    maxH: 3,
                    minH: 1,
                    i: element.id,
                }
                this.groupPositions.push(el);
                this.groupMeasurers.push(element)
            })
        })

        this.isLoading = false
        this.$emit("loaded")
        if(this.$session.get("isMobile")){
            this.windowResize()
        }
    },

    beforeDestroy() {
        window.removeEventListener("resize", () => { this.windowResize() });
    },

    watch: {
        groupPositions: {
            deep: true,
            handler() {
                if (!this.isLoading) {
                    this.groupPositions.forEach((position, index) => {
                        this.groupMeasurers[index].height = position.h
                        this.groupMeasurers[index].width = position.w
                        this.groupMeasurers[index].positionX = position.x
                        this.groupMeasurers[index].positionY = position.y
                    })
                    this.$emit("change", this.groupMeasurers)
                }
                this.dataLoaded = true
            }
        }
    }
}