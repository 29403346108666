<template>
    <div>
        <Transition name="slide-fade">
            <div v-if="!run">
                <div class="w-80 mb-4 mr-2 ml-2" style="padding: 10px;">
                    <page-header :name="$i18n.t('presentation')" :button="false"/>
                </div>
                    <div class="w-100 d-flex flex-column align-items-center">
                        <div class="w-75 text-center">
                            <h2>{{$i18n.t('dashboards')}}</h2>
                            <hr>
                        </div>
                        <relation-table :showTimeInput="selectedDashboards.length > 1" @changeTime="changeTime" class="relation m-0" :title="$i18n.t('dashboards')" :id="`${id}_0`" :items="unselectedDashboards" :insertedItems="selectedDashboards" @selectedItems="(elements) => this.selectedDashboards = elements"/>
                        <div class="w-10 d-flex flex-column align-items-center mt-3">
                            <action-button 
                                type="button" 
                                :title="$i18n.t('start')" 
                                buttonStyle="outline-success" 
                                class="m-0" 
                                @buttonAction="startPresentation"/>
                        </div>
                </div>
            </div>
            <div v-else class="transition-container">
                <Transition name="slide-fade">
                    <div v-if="show && id > 0">
                        <div class="w-100">
                            <component :is="'dashboard-panel'" :id="id.toString()" :fullscreen="true" :zoom="zoom" @zoomChange="zoomChange"/>
                        </div>
                    </div>
                </Transition>

            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    props: {
        time: {
            type: Number,
            required: false,
            default: 10
        },
    },

    data() {
        return {
            show: true,
            run: false,
            dashboards: [],
            selectedDashboards: [],
            id: 0,
            zoom: 100,
            isFullscreen: false

        }
    },

    methods: {

        zoomChange(zoom) {
            this.zoom = zoom;
        },

        goFullscreen() {
			const element = document.documentElement
			if (element.requestFullscreen) element.requestFullscreen();
			else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen(); /* Safari */
			else element.msRequestFullscreen(); /* IE11 */
		},

        async startPresentation() {
            this.run = true;
            this.goFullscreen()
            if(this.selectedDashboards.length > 1){
                const sleep = (time) => {return new Promise(resolve => setTimeout(resolve, time * 1000))}
                while(this.run){
                    for(let activeDashboard of this.selectedDashboards){
                        this.show = true
                        var sleepTime = activeDashboard.time != undefined ? activeDashboard.time : 10
                        this.id = activeDashboard.id 
                        await sleep(sleepTime)
                        this.show = false
                        await this.$nextTick()
                    }
                }
            }else{  
                let activeDashboard = this.selectedDashboards[0]
                this.show = true;
                this.id = activeDashboard.id
            }   

        },

        changeTime(values){
            const {time, id} = values
            var dashboard = this.selectedDashboards.find(dashboard => dashboard.id == id)
            dashboard.time = time
        }
    },

    computed:{
        unselectedDashboards(){
            return this.dashboards.filter(d => this.selectedDashboards.find(s => s.id == d.id) == undefined)
        }
    },

    created() {
        console.warn(this.begin)
        console.log('CREATED');
        this.service = new this.$dashboardService();

        this.service
        .listByUserId(this.$session.get('logged_id'))
        .then(dashboards => {
            this.dashboards = dashboards.map(item => { item.time = 10; return item});
        })

        document.addEventListener("fullscreenchange", () => {
			this.isFullscreen = !this.isFullscreen
			this.$emit("toggleFullscreen", this.isFullscreen)
            if(!this.isFullscreen){
                this.show = false
                this.run = false
            }
		})
    },

    beforeDestroy(){
        clearTimeout(this.presentation);
    }
    
}

</script>

<style lang="scss" scoped>

    .transition-container{
        width: 100%;
        overflow: hidden;
    }

    .slide-fade-enter-active {
    transition: all 0.8s ease-out;
    }

    .slide-fade-leave-active {
    transition: all 0.8s ease-out;
    }

    .slide-fade-enter{
        transform: translateX(100%);
        opacity: 0;
    }
    .slide-fade-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }

    .time {
        margin-left: 5%;
    }

    .relation {
        margin-left: 5%;
        width: 90%;
    }


</style>

